import React from "react"
import Button from "@material-ui/core/Button"
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import InstagramIcon from "@material-ui/icons/Instagram"
import Link from "@material-ui/core/Link"
import Fab from "@material-ui/core/Fab"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "85vh",
      width: "100%",
    },
    maingrid: {
      height: "80vh",
      paddingTop: "70px",
    },
    title: {
      color: theme.palette.secondary.main,
      fontSize: "2.5em",
      marginTop: "0px",
      marginBottom: "0px",
      margin: "2rem",
      textAlign: "center",
      maxWidth: "960px",
      minWidth: "200px",
    },
    button: {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      fontFamily: "inherit",
      borderRadius: "6px",
      "&:hover": {
        color: theme.palette.secondary.main,
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      width: 180
    },
    icobutton: {
      marginRight: "0.5rem",
    },
    link: {
      margin: "0.5rem",
      "&:hover": {
        textDecoration: "none",
      },
    },
    more: {
      position: "absolute",
      bottom: "-26px",
      left: "calc(50vw - 28px)",
      background: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      justifyContent: "center",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        boxShadow: "0 0 4px rgba(0,0,0,.14), 0 10px 10px rgba(0,0,0,.28)",
      },
    },
  })
)

export interface HeroProps {
  children: React.ReactElement
  backgroundimage: FluidObject
}

export const Hero: React.FC<HeroProps> = ({ children, backgroundimage}) => {
  const classes = useStyles()
  const theme = useTheme();

  const verticalHero = useMediaQuery(theme.breakpoints.down(513));

  // Background stacking
  const backgroundFluidImageStackVertical = [
    backgroundimage,
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.01))`,
  ].reverse()
  const backgroundFluidImageStack = [
    backgroundimage,
    `linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.01))`,
  ].reverse()

  return (
    <BackgroundImage
      Tag="section"
      className={classes.root}
      fluid={
        verticalHero ? backgroundFluidImageStackVertical : backgroundFluidImageStack
      }
      backgroundColor={`#040e18`}
    >
      <Grid
        container
        className={classes.maingrid}
        justify="center"
        alignItems="center"
      >
        <Grid container item xs justify="center" alignItems="center">
          <h1 className={classes.title}>{children}</h1>
        </Grid>
        <Grid
          container
          item
          xs
          justify="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Link
              className={classes.link}
              href="https://wa.me/380639891253"
              color="inherit"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className={classes.button}
                size="large"
                variant="outlined"
              >
                <WhatsAppIcon className={classes.icobutton} />
                WhatsApp
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={classes.link}
              href="https://www.instagram.com/where.again/"
              color="inherit"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className={classes.button}
                size="large"
                variant="outlined"
              >
                <InstagramIcon className={classes.icobutton} />
                Instagram
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Fab
        id="fab"
        className={classes.more}
        aria-label="add"
        size="large"
        href="#fab"
      >
        <ExpandMoreIcon />
      </Fab>
    </BackgroundImage>
  )
}
// export const Button: React.FC<ButtonProps> = ({
//     secondary = false,
//     shadow = false,
//     children,
//     ...props
//    }) => {
//     const TheButton = secondary ? SecondaryButton : getButton(shadow);
//     return <TheButton {...props}>{children}</TheButton>;
//    };
