import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

import Img, { FluidObject } from "gatsby-image"
import { Paper } from "@material-ui/core"
import ReactMarkdown from "react-markdown"

export interface FeatureProps {
  thumbnail: FluidObject
  title: string
  description: string
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: "inline-block",
      backgroundColor: theme.palette.secondary.main,
      marginTop: "4rem",
      borderRadius: "5px",
      height: "calc( 100% - 4rem )",
    },
    icon: {
      height: "150px",
      width: "150px",
      padding: "1rem",
      borderRadius: "4%",
      marginTop: "-3rem",
      marginBottom: "1rem",
      marginLeft: "auto",
      marginRight: "auto",
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      padding: "1rem",
      textAlign: "center",
      height: "90px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    description: {
      padding: "1rem",
      fontSize: "0.9em",
      textAlign: "center",
    },
  })
)

export const Feature: React.FC<FeatureProps> = ({
  thumbnail,
  title,
  description,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.icon}>
        <Img fluid={thumbnail} alt={title} />
      </Paper>
      <h2 className={classes.title}>{title}</h2>
      <ReactMarkdown className={classes.description} source={description} />
    </div>
  )
}
